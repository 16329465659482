import React from 'react';
//import React, { useState } from 'react';

// import Video from '../../videos/video.mp4';
import {
	HeroContainer,
	HeroContent,
	HeroH1,
	HeroP
} from './HeroElements';
import styled from 'styled-components';
import ControlledCarousel from '../Carousel';





function HeroSection() {
	/*const [hover, setHover] = useState(false);*/

	return (

		
		<HeroContainer id='home'>

			{/* <HeroBg>
        <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg> */}
			<HeroContent>


				<HeroH1>Tapasya Charitable Trust</HeroH1>

				 <ControlledCarousel />
				<HeroP>
					Welcome to Tapasya: Empowering Vision!
					According to the World Health Organization, vision loss is an utmost serious global concern that
					affects millions of individuals worldwide. It casts a profound shadow, not just limiting clear sight but
					also stealing away independence, quality of life, and active participation in daily activities. Tasks that
					many take for granted, like reading, recognizing faces, or navigating independently, become
					formidable challenges for those with vision impairments.
					The ramifications of vision loss reach far and wide, infiltrating every aspect of life. It cripples
					educational opportunities, obstructs employment prospects, and perpetuates cycles of poverty and
					exclusion. The burden falls upon healthcare systems, families, and caregivers who must provide
					unwavering support to individuals with visual impairments.
					Moreover, vision loss wreaks havoc on mental and emotional well-being. Isolation, depression,
					anxiety, and shattered self-confidence are just a few of the agonizing emotions that individuals and
					their loved ones grapple with. We must not underestimate the toll it takes on their spirits.
					Addressing vision loss is not only a health imperative but a matter of social justice. Tapasya
					advocates for accessible and affordable eye care services, early detection, timely interventions, and
					comprehensive rehabilitation programs. These vital elements work in unison to mitigate the impact
					of vision loss, ultimately enhancing the overall well-being of those affected.
					Vision loss transcends the individual level; it cripples society’s at large, stalling progress and
					development. By acknowledging the gravity of this issue and joining forces, Tapasya leads the charge
					in prioritizing eye health. We champion preventive measures and establish the support systems
					necessary to ensure that no one is left behind due to vision impairment.
					Join us in our quest to empower vision and make a lasting impact on the world...
				</HeroP>

			</HeroContent>
		</HeroContainer>
		
	);
}

export default HeroSection;
