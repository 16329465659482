export const homeObjOne = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'ABOUT TAPASYA',
  // headline: 'ABOUT TAPASYA',
  description:
    `Tapasya in Sanskrit means a meditative endeavors to attain a goal despite all odds. 
    The mission of Tapasya Charitable Trust is to rebuild the lives of socially deprived individuals through education, training, research, technology, programs encouraging self confidence. 
    with an objective to help under privileged people in India The organization was founded in the year 2002 to serve the nation. 
    Tapasya Trust has personal experience in re engineering social adversity; 
    however we also welcome people from different walks of life who wish to contribute with passion for the development of mother land.`,
  imgStart: true,
  img: require('../../images/T8.webp'),
  alt: 'Inauguration of 1st Digital Audio Book',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Unlimited Access',
  headline: 'Login to your account at any time',
  description:
    'We have you covered no matter where you are located. All you need is an internet connection and a phone or computer.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/T2.webp'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Join our Team',
  headline: 'Creating an account is extremely easy',
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: 'Start Now',
  imgStart: false,
  img: require('../../images/T3.webp'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjFour = {
  id: 'blindPrevention',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Blind Prevention',
  // headline: 'Login to your account at any time',
  description:
    `Tapasya conduct general eye and health checkup with the help of doctors who volunteer for this noble service. We conduct free medical health checkup to the people living in slums. We learned that counseling is badly needed for women to maintain their health; mainly we found that nutritional value in their diet was missing for various reasons. 
    No strong evidence, we could find; but our believe gained strength that poor diet contribute to early cataract / eye disorders among women and children. However we ensure that they receive spectacles right after check up and others are assisted to get their cataract treated at the earliest.  
    To conduct these essential services we need dedicated resources so that we can reach out to far remote areas of villages and tribal areas
    `,
  buttonLabel: 'Click here to see how we bring change in the life of needy',
  imgStart: true,
  img: require('../../images/T2.webp'),
  alt: 'Giving demo on MP3 digital audio book',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjFive = {
  id: 'contactus',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Contact Us',
  // headline: 'Login to your account at any time',
  description:
    `Address: Plot no 3, Shiva Nagar colony,
    Sanikpuri Secunderabad 500094 
    Email: info@tapasyacharitabletrust.org 
    Phone Number: +91-9618226007 
    Website: www.tapasyacharitabletrust.org`,
  buttonLabel: 'Contact Us',
  imgStart: false,
  img: require('../../images/T7.webp'),
  alt: 'Inauguration of 1st Digital Audio Book',
  dark: false,
  primary: false,
  darkText: true
};


