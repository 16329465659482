import React from 'react';
import {
  OurAchievements,
  AchievementsH1,
  AchievementsWrapper,
  AchievementsP
} from './ServicesElements';

const Achievements = () => {
  return (
    <OurAchievements id='ourAchievements'>
      <AchievementsWrapper>
        <AchievementsH1>Tectonic Policy Shift For The Benefit Of Blind</AchievementsH1>
        <AchievementsP>
          Tapasya Charitable Trust is non-profit organization started in the year 2002 with an objective to empower blind individuals at all stages
          and spears of their life. We firmly believe that every other individual who have physical limitation or lost sensory organs has
          right to live with dignity. Our learning is that with the help of proper training and technology, persons with different abilities will overcome challenges of day to day living.
          Right from its inception (2002) Tapasya always believed in executing innovative and quality programmes that bring systemic change.
          In the year 2003 -04:
   <br /> 
          Tapasya pioneered the use of MP3 tracks in education.We discovered Text Books for higher education in Braille are in short supply. We made audio records of academic materials and
          distributed them to blind students along with MP3 players. The students found listening to the lessons was far more helpful than
          Braille books and could pursue education better. Impressed by the concept, the state government too sponsored some of the equipment.
          Happily, now more organizations have taken up distributing audio recorded lessons and MP3 players.
          <br />The problem that Tapasya encountered:
          <br />• One printed text book of 300 pages is equal to 1 & half foot tall braille book with 2100 braille sheets
          <br />• If the same book to be converted into audio format, it takes 25 audiocassettes. To maintain entire course books it takes 150 cassettes
          <br />• Copying, retrieving information becomes uphill task
          <br />• As a result several students were unable to complete their education on time.
          <br />Under this excrement, Tapasya not only covered more than hundred blind students in five colleges but also sensitized the department of
          disability welfare to amend government order (GO) that procured and provide latest assistive aids, like MP3 audio players, IPod  for the use of blind students across the state.
   <br /> 
          The results were encouraging and it was noticed that there was an increase in passing percentage. 
          Today every blind student perusing higher education receives daisy player and laptop with a screen reading software. 
          This tectonic shift in the government policy took place purely with the help of Tapasya's volunteers under
          the leadership of Sri Vishnu M Avula and with the help of financial support extended by Speck Systems PVT LTD.
   <br /> 
          Today this is a regular Government programme that is implemented by both Telangana and Andhra Pradesh States. 
          As a result countless blind students have been benefited over the years through this initiative.
        </AchievementsP>
      </AchievementsWrapper>
    </OurAchievements>
  );
};

export default Achievements;
