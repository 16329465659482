import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

const T1 = require('../../images/T1.webp');
const T3 = require('../../images/T3.webp');
const T4 = require('../../images/T4.webp');
const T5 = require('../../images/T5.webp');
const T6 = require('../../images/T6.webp');
const T7 = require('../../images/T7.webp');

const ControlledCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const styleImg = {
    paddingTop: '10px'
  };

  return (
 
    <Carousel slide={false} activeIndex={index} onSelect={handleSelect} >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src= {T1} style={styleImg}
          alt="Talk by Retd.Crime Branch officer from Railways Venkatraman Narasimhan"
        />
        <Carousel.Caption>
          <span style={{backgroundColor: 'black'}}>Talk by Retd.Crime Branch officer from Railways Venkatraman Narasimhan</span>
        </Carousel.Caption>
      </Carousel.Item>
      {/* <Carousel.Item>
      <img
        className="d-block w-100"
        src= {T2} style={styleImg}
        alt="Giving demo on MP3 digital audio book"
      />
      <Carousel.Caption>
        <span style={{backgroundColor: 'black'}}>Giving demo on MP3 digital audio book</span>
      </Carousel.Caption>
      </Carousel.Item> */}
      <Carousel.Item>
        <img
          className="d-block w-100"
          src= {T3} style={styleImg}
          alt="Volunteers from Franklin Templeton"
        />
        <Carousel.Caption>
          <span style={{backgroundColor: 'black'}}>Volunteers from Franklin Templeton</span>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src= {T4} style={styleImg}
          alt="Disturbution of folding stick & leather pouch"
        />
        <Carousel.Caption>
          <span style={{backgroundColor: 'black'}}>Disturbution of folding stick & leather pouch</span>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src= {T5} style={styleImg}
          alt="Eye camp at Govt"
        />
        <Carousel.Caption>
          <span style={{backgroundColor: 'black'}}>Eye camp at Govt</span>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src= {T6} style={styleImg}
          alt="Yendada eye camp 2018 photo"
        />
        <Carousel.Caption>
          <span style={{backgroundColor: 'black'}}>Yendada eye camp 2018 photo</span>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src= {T7} style={styleImg}
          alt="Inauguration of 1st Digital Audio Book"
        />
        <Carousel.Caption>
          <span style={{backgroundColor: 'black'}}>Inauguration of 1st Digital Audio Book</span>
        </Carousel.Caption>
      </Carousel.Item>
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src= {T8} style={styleImg}
          alt="Second slide"
        />
        <Carousel.Caption>
          <span style={{backgroundColor: 'black'}}>Inauguration of 1st Digital Audio Book</span>
        </Carousel.Caption>
      </Carousel.Item> */}	
    </Carousel>
    
  );
}

export default ControlledCarousel;
