import React from 'react';
import {
  ServicesContainer,
  ServicesWrapper,
  ServicesCard,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  const styleBtn = {
    borderRadius: '5px',
    marginBottom: '20px',
    margin:'10px',
    padding: '5px'
  };
  return (
    <ServicesContainer id='services'>
      {/* <ServicesH1>Our Services</ServicesH1> */}
      <ServicesWrapper>
        <ServicesCard>
          <ServicesH2>Enabling Blind</ServicesH2>
          <ServicesP>
            <button style={styleBtn}>Folding Stick Donation 26th Jan 2013</button>
            <button style={styleBtn}>Job Placement for Blind in weaving sector</button>
            <button style={styleBtn}>Computer Training Program</button>
          </ServicesP>
          <ServicesH2>Other Programs</ServicesH2>
          <ServicesP>
            <button style={styleBtn}>Blood Donation & General Checkup</button>
            <button style={styleBtn}>Offering meals at Orphan Shelter Secunderabad</button>
            <button style={styleBtn}>Free Weekly Gyn Checkups</button>
            <button style={styleBtn}>Digital Audio Bookfor Blind</button>
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesH2>Blindness Prevention</ServicesH2>
          <ServicesP>
            <button style={styleBtn}>Eye camp conducted at Damayguda Hyderabad</button>
            <button style={styleBtn}>Eye camp conducted in Yendada </button>
            <button style={styleBtn}>Cornea Donation Rally </button>
            <button style={styleBtn}>Eye camp held at J.JNagar colony Hyd </button>
            <button style={styleBtn}>Cataract surgeries 21st September 2018 </button>
            <button style={styleBtn}>Eye camp at Shiva Nagar Hyd </button>
            <button style={styleBtn}>Cataract Surgeries during 30/06 to 07/07/2019 </button>
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
