import React from 'react';
import {
  OurEula,
  EulaH1,
  EulaWrapper,
  EulaP
} from './ServicesElements';

const Eula = () => {
  return (
    <OurEula id='gyan_rathan_eula'>
      <EulaWrapper>
        <EulaH1>Gyan Rathan User Licence Agreement</EulaH1>
        <EulaP>

          <ol>
            <li>
              <EulaP>This end user licence agreement is a legally binding agreement between you, the licensee,
                and the developer, licenser of the Gyan rathan app, effective from the date of purchase.</EulaP>
            </li>
            <li>
              <EulaP><strong>Definitions:</strong></EulaP>
              Any term used in this agreement shall be construed as billow.
              <ol>
                <li>
                  <EulaP>Application: “ means the Gyan Rathan application and shall includes all its features and
                    updates made to the application by the developer time to time.”</EulaP>
                </li>

                <li>
                  <EulaP>
                    Licensor “ means the developers of the application and includes its associates, affiliates,
                    assignees and any person, company, partnership or any other entity thereby authorised
                    for the purpose of managing, updating, developing or modifying the application but shall
                    not include users, in what so ever manner.”</EulaP>
                </li><li>
                  <EulaP>Licensee “ shall include the downloaders, students, educators, parents / guardians using
                    / assisting the use / advising the use/ availing the use / content through the application
                    and authorised for such use / assistance by the licensor. ”</EulaP>
                </li><li>
                  <EulaP> Student “ means the users of the application in what so ever manner for accessing the
                    content in the application for what so ever purpose including learning, reading, teaching,
                    research and skill development but shall not include any unauthorised user / infringer.”</EulaP>
                </li><li>
                  <EulaP>Parent / guardian “ shall include any elder responsible for upbringing, monitoring /
                    guiding the child for any purpose including his / her education and shall include all
                    persons authorised for this purpose by any law in force.””</EulaP>
                </li><li>
                  <EulaP> Educator “means any person using the application for the purpose of teaching,
                    educating, training, research, guidance, and includes any person advising a student,
                    parent or an educator for use/ availing the content/ features of the application.”</EulaP>
                </li><li>
                  <EulaP> Library “ means libraries or any of their branches, physical or digital made accessible by
                    the application.”</EulaP>
                </li><li>
                  <EulaP> Content “ means and includes books , articles, journal , research paper, case study or
                    any part of any book, article, journal, case study, research paper or any other content
                    governed by the applicable copyright laws.</EulaP>
                </li><li>
                  <EulaP> Device “ means a smart phone, desktop, laptop, tablet, and any other gadget on which
                    the application is being accessed / downloaded.”</EulaP>
                </li><li>
                  <EulaP> Ownership” means the absolute right to own, develop, distribute, modify, sell,
                    transfer, grant / reject the right to use, terminate the right to use, alienate and avail
                    appropriate legal remedy against any inappropriate / unauthorised use of any content/
                    feature/ facilities in the application. “</EulaP>
                </li><li>
                  <EulaP> License “ means and includes the right to use the application and its features and
                    facilities along with updates and developments made from time to time by the licenser
                    but shall not include the right to reproduce the content, or commercial use of the
                    application in what so ever manner.”</EulaP>
                </li><li>
                  <EulaP> Unauthorised use “means any use / exploitation of the application or any of its
                    features in any manner what so ever except the use expressly / impliedly provided
                    through this agreement and includes unauthorised access to any of the libraries without
                    a valid membership.”</EulaP>
                </li><li>
                  <EulaP> Law “includes the laws of India in force at the time of this making this agreement.</EulaP>

                </li><li>
                  <EulaP> “any use of singular words in this agreement shall be construed to include their
                    plurals and any reference to any gender in what so ever manner including through
                    pronouns or any other expressions shall be construed to include all the genders. “</EulaP>
                </li><li>
                  <EulaP> “any term / word / expression / connotation used in this agreement but not defined
                    herewith, shall have the meaning assigned to them under the General Clauses Act
                    (1897).”</EulaP>
                </li></ol></li><li>
              <strong>Terms</strong>
              <ol>
                <li>
                  <EulaP>Whereas the Application has been developed by the Gyan ratan project of Tapasya
                    charitable trust to facilitate the visually challenged with access to various digital libraries
                    through a device.</EulaP>
                </li><li>
                  <EulaP> Whereas the application facilitates access to libraries through membership in respective</EulaP>
                  libraries.
                </li><li>
                  <EulaP> Whereas the application aims to provide seamless access to diverse libraries for
                    enabling the visually impaired to reach out to various books and articles.</EulaP>
                </li><li>
                  <EulaP> Whereas the licensee through search and download has requested the licensor for the
                    licence/ authorisation for non-commercial use of the application for the purposes of
                    accessing libraries, the licenser granted the same under the terms and conditions
                    contained in this agreement.</EulaP>
                </li></ol></li>
            <li>
              <strong>Grant of License</strong>
              <ol><li>
                <EulaP> by agreeing to the terms of this agreement and being authorised by the licenser the
                  downloader of the application becomes the licensee and entails the right to use, the
                  application for non-commercial, education and skill development purposes.</EulaP>
              </li><li>
                  <EulaP> Obtaining licence to use the application does not enable the use or access to any library
                    to which the licensee is not a member</EulaP>
                </li><li>
                  <EulaP> Any licensee accessing digital content of any library what so ever through the
                    application should hold an active membership in the respective library.</EulaP>
                </li><li>
                  <EulaP> The licensee is bound to adhere to the access rules of each library and its content
                    therewith.</EulaP>
                </li></ol></li><li>
              <strong>Content</strong>
              <ol><li>
                <EulaP> the licenser does not warrant or in any manner what so ever certify the type of content
                  or its accuracy available with any of the libraries accessible through the application.</EulaP>
              </li><li>
                  <EulaP> The licenser does not hold himself or any of his stakeholder responsible for any kind of
                    access to any inappropriate content weather deliberate or not effecting rights / believes
                    of any user therewith.</EulaP>
                </li></ol></li>
            <li>
              <strong> Students</strong>
              <ol><li>
                <EulaP> The licenser does not assure the appropriacy and accuracy of any content accessible in
                  any of the listed libraries.</EulaP>
              </li><li>
                  <EulaP>The student holds the accountability to ensure valid membership to the libraries before
                    accessing any content therewith.</EulaP>
                </li><li>
                  <EulaP> Students along with their guardians are liable for any violation/ infringement arising out
                    of any information disclosed to the application or to any library while accessing its
                    content.</EulaP>
                </li><li>
                  <EulaP> The licenser, developer or any member associated with the application are in any
                    manner what so ever not liable for any consequence’s arising out of accessing any
                    inappropriate content from any library through the application.</EulaP>
                </li><li>
                  <EulaP> The membership once obtained persists and access would be denied only if the
                    respective library for any reason what so ever closes the member ship of the licensee.</EulaP>

                </li><li>
                  <EulaP> The licenser in no manner undertakes to monitor / regulate access to any content which
                    might be deemed unfit for age or any circumstance of the students accessing the same.</EulaP>
                </li><li>
                  <EulaP> Students are liable for any copy right violation or unfair use of the content accessible
                    through the application.</EulaP>
                </li><li>
                  <EulaP> Students are liable for any kind of hacking or violations what so ever and the licenser
                    reserves the right to initiate appropriate legal proceedings in occurrence of any such
                    event.</EulaP>
                </li></ol></li>
            <li>
              <strong>Parents</strong>
              <ol><li>
                <EulaP>parents / guardians responsible for the upbringing and care of the child hold the entire
                  liability / responsibility arising in any manner what so ever by use / access to the content
                  through the application.</EulaP>
              </li><li>
                  <EulaP> Parent / guardian are accountable /responsible to ensure that the child accesses only
                    that content deemed fit for their age / education / skill development purposes.</EulaP>
                </li><li>
                  <EulaP> Parent/ guardian holds the absolute liability in case of any violations or any form of
                    hacking activities entailing criminal liability indulged into by any minor accessing the
                    content and licenser reserves the right to initiate appropriate legal proceedings in
                    occurrence of any such event.</EulaP>
                </li></ol></li>
            <li>
              <strong>Educators</strong>
              <ol><li>
                <EulaP> teachers / trainers / educators are responsible for any guidance / advise on accessing any
                  content through the application and the licensor does not undertake to ensure the
                  suitability of the content what so ever to the child’s development.</EulaP>
              </li><li>
                  <EulaP> teachers / educators / trainers guiding research and development by the student shall
                    take all appropriate measures to ensure that no copy right violations are made during
                    the same.</EulaP>
                </li></ol></li><li>
              <strong>Liability</strong>
              <ol><li>
                <EulaP>The licensor, it’s stake holders, developers, managers and anyone directly or indirectly
                  associated with development / building up/ functioning/ updating of the application shall not
                  hold any liability/ responsibility for any damage whatsoever arising out of use of the
                  application or excess of inappropriate content whatsoever by the licensee.</EulaP>
              </li><li>
                  <EulaP> The licensor , it’s stake holders, developers, managers and anyone directly or indirectly
                    associated with development / building up/ functioning/ updating of the application shall not
                    hold any liability/ responsibility for any copyright notices issued to any licensee by any library
                    or author of any book/ article/ journal/ case study/ research paper issued to the licensee for
                    any violation therewith.</EulaP>
                </li><li>
                  <EulaP> The licensor, it’s stake holders, developers, managers and anyone directly or indirectly
                    associated with development / building up/ functioning/ updating of the application shall not
                    hold any liability/ responsibility for any proceedings / action taken up by any library for
                    unauthorised access or failure to adhere to any bylaws therewith.</EulaP>
                </li><li>
                  <EulaP> The licensor , it’s stake holders, developers, managers and anyone directly or indirectly
                    associated with development / building up/ functioning/ updating of the application shall not
                    hold any liability/ responsibility for any consequence whatsoever arising out of use of any
                    content for any purpose of research / training purposes or any action taken as a result of any
                    knowledge / information acquired through such access.</EulaP>
                </li><li>
                  <EulaP> The licensor , it’s stake holders, developers, managers and anyone directly or indirectly
                    associated with development / building up/ functioning/ updating of the application shall not
                    hold any liability/ responsibility for any action whatsoever taken up by the library including the
                    termination of membership / restriction of access for any reason whatsoever including any
                    technical errors / technological difficulties and the licensee shall directly raise their grievance
                    directly with the library for resolution of the same.</EulaP>
                </li><li>
                  <EulaP>The licensor, it’s stake holders, developers, managers and anyone directly or indirectly
                    associated with development / building up/ functioning/ updating of the application shall not
                    hold any liability/ responsibility for any consequences arising out of any disclosures made by
                    the licensee to any unauthorised individual of any information which might lead to violation
                    of any nature whatsoever towards the licensee.</EulaP>
                </li><li>
                  <EulaP> The licensor, it’s stake holders, developers, managers and anyone directly or indirectly
                    associated with development / building up/ functioning/ updating of the application shall not
                    hold any liability/ responsibility for using the content provided in the form of suggestions/
                    feedback for the purpose of enhancing / improving the features / performance of the
                    application for violation of copyright in any manner whatsoever for the person providing the
                    said suggestion / feedback.</EulaP>
                </li></ol></li><li>
              <strong>Dispute resolution</strong>
              <ol><li>
                <EulaP>any dispute arising between the licensor / developer / stakeholders of the application
                  and the licensee / user shall be settled amicably by the parties.</EulaP>
              </li><li>
                  <EulaP>in case of failure of the parties to settle the disputes amicably, the same shall be done
                    through appropriate legal proceedings by the parties.</EulaP>
                </li><li>
                  <EulaP>the courts of jurisdiction for settling of any disputes with respect to this agreement shall
                    be the courts at Hyderabad.</EulaP>
                </li></ol></li></ol>

        </EulaP>
      </EulaWrapper>
    </OurEula>
  );
};

export default Eula;
